.side__menu {
    height: calc(100dvh - var(--navbar-height));
    background: var(--color-gray-100);
    display: grid;
    place-items: center;
    position: fixed;
    margin-top: var(--navbar-height);
    top: 0;
    left: 0;
    z-index: 99;
}

.side__menu-container {
    height: 100%;
    width: 8rem;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    position: relative;
    box-shadow: var(--box-shadow);
    transition: var(--transition);
}

.side__menu-container.show__side-menu {
    width: 8rem;
}

.side__menu-container.hide__side-menu {
    width: 0rem;
}

.side__menu-toggle-btn {
    background: var(--color-gray-200);
    color: var(--color-gray-600);
    position: absolute;
    cursor: pointer;
    margin: 0 0 0 8rem;
    padding: 0.5rem 0.75rem;
    left: -0.9rem;
    border-radius: 2rem;
    transition: var(--transition);
}


.side__menu-toggle-btn.hide__side-menu {
    margin: 0;
}

.side__menu-links {
    display: grid;
    width: 100%;
    font-size: 0.6rem;
    font-weight: 600;
    padding-top: 2rem;
    overflow: hidden;
    text-wrap: nowrap;
    grid-template-columns: 1fr;
    cursor: pointer;
    transition: var(--transition);
}
.side__menu-links svg{
    font-size: 1.2rem;
}

.side__menu-links li {
    transition: var(--transition);
}

.side__menu-links p {
    display: inline-block;
}

.side__menu-links p:last-child {
    margin-left: 0.5rem;
}

.side__menu-links li:hover {
    background-color: var(--color-analogous-1);
}

.side__menu-links a {
    display: flex;
    align-items: center;
    padding: 0.8rem;
    color: var(--color-gray-500);
}

.side__menu-links li:hover a {
    color: var(--color-gray-100);
}

.side__menu-links li:has(.side__menu-link.side__menu-active-nav) {
    cursor: default;
    background-color: var(--color-analogous-2);
}
.side__menu-links li:has(.side__menu-link.side__menu-active-nav) a{
    color: var(--color-gray-100);
}