*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}


:root {

  --primary-hue: 210;
  --gray-hue: 240;
  --color-primary: hsl(var(--primary-hue), 100%, 50%);
  --color-primary-variant: hsl(var(--primary-hue), 100%, 65%);
  --color-analogous-1: hsl(calc(var(--primary-hue) - 30), 60%, 45%);
  --color-analogous-2: hsl(calc(var(--primary-hue) + 30), 40%, 45%);
  --color-analogous-3: hsl(calc(var(--primary-hue) + 60), 40%, 50%);
  --color-analogous-4: hsl(calc(var(--primary-hue) - 60), 40%, 50%);
  --color-analogous-5: hsl(calc(var(--primary-hue) + 90), 40%, 50%);
  --color-analogous-6: hsl(calc(var(--primary-hue) - 90), 40%, 50%);
  --color-gray-100: hsl(var(--gray-hue), 20%, 98%);
  --color-gray-200: hsl(var(--gray-hue), 47%, 94%);
  --color-gray-300: hsl(var(--gray-hue), 26%, 85%);
  --color-gray-400: hsl(var(--gray-hue), 24%, 64%);
  --color-gray-500: hsl(var(--gray-hue), 16%, 41%);
  --color-gray-600: hsl(var(--gray-hue), 44%, 25%);
  --color-gray-700: hsl(var(--gray-hue), 48%, 15%);
  --color-ok: #00E676;
  --color-error: #FF1744;

  --container-width-lg: 80%;
  --container-width-md: 90%;

  --navbar-height: 4rem;

  --transition: all 500ms ease;

  --box-shadow: 0px 35px 35px rgba(10, 10, 10, 0.1);

  /* Dark background colors */
  --bg-dark-1: #121212;
  --bg-dark-2: #1E1E1E;

  /* Light background colors */
  --bg-light-1: #F5F5F5;
  --bg-light-2: #E0E0E0;

  /* Dark text colors */
  --text-dark-1: #FFFFFF;
  --text-dark-2: #BDBDBD;

  /* Light text colors */
  --text-light-1: #1E1E1E;
  --text-light-2: #333333;

  /* Accent color */
  --primary-color: #007ffd;

  /* Error color */
  --error: #FF1744;

  /* Action colors */
  --action-active: #FFFFFF;
  --action-hover: #007ffd;
  --inactive: lightgray;
}


.errmsg {
  display: inline-block;
  background-color: var(--bg-dark-1);
  color: var(--error);
  padding: 0.25em;
  margin-bottom: 0.5em;
}

.errmsg a:any-link {
  color: var(--error);
  text-decoration: underline;
}

/* GENERAL STYLES */
body {
  font-family: 'Montserrat', sans-serif;
  color: var(--color-gray-600);
  line-height: 1.7;
  overflow-x: hidden;
  background: var(--color-gray-100);
}

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}

.outlet {
  margin-top: var(--navbar-height);
  min-height: calc(100dvh - var(--navbar-height));
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  color: var(--color-gray-600);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

a {
  color: var(--color-gray-600);
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

.content-grid {
  --padding-inline: 1rem;
  --content-max-width: 120ch;
  --breakout-max-width: 135ch;

  --breakout-size: calc((var(--breakout-max-width) - var(--content-max-width)) / 2);

  display: grid;
  grid-template-columns:
    [full-width-start] minmax(var(--padding-inline), 1fr) [breakout-start] minmax(0, var(--breakout-size)) [content-start] min(100% - (var(--padding-inline) * 2),
      var(--content-max-width)) [content-end] minmax(0, var(--breakout-size)) [breakout-end] minmax(var(--padding-inline), 1fr) [full-width-end];
}

.content-grid> :not(.breakout, .full-width),
.full-width> :not(.breakout, .full-width) {
  grid-column: content;
}

.content-grid>.breakout {
  grid-column: breakout;
}

.content-grid>.full-width {
  grid-column: full-width;

  display: grid;
  grid-template-columns: inherit;
}

.btn {
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  padding: 0.9rem 2rem;
  color: var(--color-gray-600);
  background-color: var(--color-gray-100);
  border: 2px solid var(--color-gray-600);
  border-radius: 0.5rem;
  transition: var(--transition)
}


.btn:hover {
  background: var(--color-primary-variant);
  color: var(--color-gray-100);
  border: 2px solid transparent;
}

.btn.disabled {
  cursor: default;
  background-color: var(--color-gray-100);
  color: var(--color-gray-200);
  border: 2px solid var(--color-gray-200);
}

.btn.lg {
  padding: 1.2rem 3rem;
  border-radius: 0.7rem;
  font-size: 1.1rem;
}

.btn.sm {
  padding: 0.4rem 1.2rem;
  font-size: 0.9rem;
}

.btn.sm:hover {
  background: var(--color-gray-600);
}

.loader__container {
  height: 100svh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__head {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.section__head span {
  background: var(--color-gray-200);
  padding: 0.8rem;
  border-radius: 1rem;
  color: var(--color-primary-variant);
  font-size: 1.5rem;
}

.card {
  background: var(--color-gray-200);
  border: 2px solid transparent;
  text-align: center;
  padding: 3rem 2rem;
  border-radius: 0 3rem 0 3rem;
  transition: var(--transition);
}

.card:hover {
  background: transparent;
  border-color: var(--color-gray-300);
  cursor: default;
}

.card span {
  width: 3rem;
  height: 3rem;
  background: var(--color-primary);
  color: var(--color-gray-600);
  font-size: 1.5rem;
  padding: 0.8rem;
  display: grid;
  place-items: center;
  margin-bottom: 1.5rem;
  margin-inline: auto;
  border-radius: 1rem;
  transition: var(--transition);
}

.card:hover span {
  background: var(--color-primary-variant);
  color: var(--color-gray-100);
}

.card small {
  margin-top: 1rem;
  display: block;
  font-weight: 300;
}


.header {
  margin-top: var(--navbar-height);
  height: 20rem;
  overflow: hidden;
  border-bottom: 2px solid var(--color-gray-300);
}

.header__container {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  place-items: center;
}

.header__container-bg {
  position: absolute;
  width: 100%;
}

.header__container-bg img {
  opacity: 0.5;
}

.header__content {
  position: relative;
  width: 44%;
  margin: 0 auto;
  text-align: center;
  color: var(--color-gray-600);
}

.header__content h2 {
  margin-bottom: 1rem;
}

.header__content p {
  color: rgba(255, 255, 255, 0.7);
}

.line__divider-container {
  vertical-align: baseline;
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  margin-bottom: 30px;
}

.line__divider-line {
  height: 0.3rem;
  width: 3rem;
  background-color: var(--color-gray-600);

}

.cookie-consent__wrapper {
  top: 85dvh;
  left: 0;
  width: 100%;
  height: 15dvh;
  position: fixed;
  color: var(--color-gray-600);
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
  z-index: 100;
  padding: 1rem;
}

.cookie-consent__text {
  padding: 1rem 0;
}

.content-list__wrapper {
  margin-top: 1rem;
  width: 100%;
}

.content-list__header {
  width: 100%;
  padding: 0.5rem;
  justify-content: center;
  background-color: var(--color-gray-100);
  border: 2px solid var(--color-gray-600);
}

.content-list__header input {
  background-color: var(--color-gray-100);
  color: var(--color-gray-600);
  font-size: 1rem;
  width: 100%;
}

.content-list__new-item {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: var(--transition);
  background-color: var(--color-gray-100);
  border: 2px solid var(--color-gray-600);
  color: var(--color-gray-600);
}

.content-list__new-item p {
  font-weight: 600;
  margin-right: 0.5rem;
}

.content-list__new-item:hover {
  background-color: var(--color-analogous-1);
  border: 2px solid transparent;
  color: var(--color-gray-100);
}

.content-list__grid {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.pagination {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.pagination button {
  height: 2rem;
  width: 2rem;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-gray-600);
  color: var(--color-gray-600);
}

.pagination button.active {
  background-color: var(--color-gray-200);
}

.form__column {
  display: grid;
  gap: 0.8rem;
  grid-auto-rows: 3.5rem;
  min-height: 12rem;
}

.form__row {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: repeat(2, 1fr);
}

.form__btn-wrapper>*:not(:last-child) {
  margin-right: 1rem;
}

/* MEDIA QUERIES (medium screens) */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  .header__content {
    width: 76%;
  }

  .cookie-consent__wrapper {
    top: 75dvh;
    height: 25dvh;
  }
}

/* MEDIA QUERIES (small screens) */

@media screen and (max-width: 600px) {

  h1 {
    font-size: 2.2rem;
    line-height: 1.3rem;
  }

  .header {
    height: fit-content;
  }

  .header__content {
    width: var(--container-width-md);
    padding: 3rem 0;
  }

  .header__content p {
    font-size: 0.85rem;
  }

  .cookie-consent__wrapper {
    font-size: 0.8rem;
    flex-direction: column;
  }

  .cookie-consent__text {
    padding: 0;
  }

  .pagination button {
    height: 1.5rem;
    width: 1.5rem;
  }

  .double-item {
    grid-row: span 2;
  }
}