footer {
    background: var(--color-gray-600);
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}

.footer__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer__container-top {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer__container-top article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container-top article p {
    margin-top: 0.5rem;
}

.footer__container-top article h4 {
    color: var(--color-gray-100);
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer__socials a {
    background: var(--color-gray-100);
    color: var(--color-primary);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.footer__socials a:hover {
    border-color: var(--color-gray-100);
    background-color: transparent;
}
.footer__links{
    width: max-content;
}
.footer__links a {
    color: var(--color-gray-100);
}

.footer__links a:hover {
    color: var(--color-gray-300);
}

.footer__container-bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}


/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {

    .footer__container {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }
    .footer__container-top {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .footer__container-top article {
        align-items: center;
    }

    .footer__container-top article p {
        text-align: center;
    }
}

/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {

    footer {
        margin-top: 7rem;
    }

    .footer__container-bottom {
        flex-direction: column-reverse;
        gap: 0.5rem;
    }

    .language {
        width: 7rem;
        align-items: center;
    }

}